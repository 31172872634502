import configProvider from "../../config.provider";
import { ApiVersion, getHttp } from "../util/http";
import { KitchenDTO } from "./KitchenDTO";

export const kitchensByCountry = async (
  country: string
): Promise<KitchenDTO[]> => {
  try {
    const response = await getHttp(
      configProvider.bistroUrl,
      ApiVersion.V1,
      `country/${country}`
    );
    const data = await response.json();
    return data.data as KitchenDTO[];
  } catch (ex: any) {
    console.error(`Error getting kicthens by country | ${ex.message}`);
    return [] as KitchenDTO[];
  }
};
