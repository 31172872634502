import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Icon,
  TextField,
  Typography,
} from "@mui/material";

import { FunctionComponent, useEffect, useState } from "react";

import { Brand } from "../../../core/brand/Brand";
import { brandsByCountry } from "../../../core/brand/BrandRepo";
import { City } from "../../../core/citi/City";
import { allCities } from "../../../core/citi/CityRepo";
import { KitchenDTO } from "../../../core/kitchen/KitchenDTO";
import { kitchensByCountry } from "../../../core/kitchen/KitchenRepo";
import { KitchenPolygon } from "../../../core/store/Store";
import { storesByQuery } from "../../../core/store/StoreRepo";
import { Platform, Platforms } from "../../../core/util/Platform";
import { COVERAGE_THEORICAL_DEFAULT } from "../../../domain/entities/Polygons";
import { timeHumanize } from "../../utils/timeHumanize";

interface Props {
  setCoverage: (coverages: KitchenPolygon[]) => void;
}
const FilterMapsBistro: FunctionComponent<Props> = (props: Props) => {
  const { setCoverage } = props;

  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);

  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(
    null
  );

  const [kitchens, setKitchens] = useState<KitchenDTO[]>([]);
  const [selectedKitchen, setSelectedKitchen] = useState<KitchenDTO | null>(
    null
  );

  const [coverages, setCoverages] = useState<KitchenPolygon[]>([]);

  const getCities = () => {
    allCities().then((cities) => {
      setCities(cities);
    });
  };

  useEffect(() => {
    setSelectedBrand(null);
    setSelectedKitchen(null);
    setSelectedPlatform(null);
    if (selectedCity) {
      brandsByCountry(selectedCity.country).then((brands) => {
        setBrands(brands);
      });
      setPlatforms(
        Platforms.filter((plat) =>
          plat.polygonCountries.includes(selectedCity.country)
        )
      );
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedCity) {
      kitchensByCountry(selectedCity.country).then((kicthens) => {
        setKitchens(
          kicthens.filter((kitchen) => kitchen.city.code === selectedCity.code)
        );
      });
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedBrand && selectedCity && selectedPlatform) {
      getStores();
    }
  }, [selectedBrand, selectedPlatform, selectedKitchen]);

  useEffect(() => {
    getCities();
  }, []);

  const getStores = () => {
    storesByQuery({
      cursor: { limit: 100000 },
      params: {
        include: ["Launched", "Paused"],
        location: {
          country: selectedCity?.country,
          city: selectedCity?.code,
          kitchen: selectedKitchen?.kitchenId,
        },
        brandId: selectedBrand?.brandId,
        ownerId: selectedPlatform?.value,
      },
      select: ["coverage"],
    }).then((response) => {
      const coverage = response.list
        .filter((store) => store.data.coverage)
        .map((store) => {
          return {
            ...store.data.coverage,
            kitchenId: store.kitchenId,
          } as KitchenPolygon;
        });

      setCoverage(coverage);
      setCoverages(coverage);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Typography
        variant="h6"
        fontWeight="600"
        fontSize="16px"
        color="primary"
        textAlign="left"
        width="100%"
      >
        Filtro
      </Typography>
      <FormControl sx={{ m: 1, width: "304px" }} size="small">
        <Autocomplete
          key={"city"}
          options={cities.sort((a, b) => -b.country.localeCompare(a.country))}
          groupBy={(option) => option.country}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione ciudad*"
              sx={{ bgcolor: "white" }}
            />
          )}
          value={selectedCity}
          getOptionLabel={(option) => option.name.toUpperCase()}
          onChange={(_, value) => {
            setSelectedCity(value ?? null);
          }}
        />
      </FormControl>

      <FormControl sx={{ m: 1, width: "304px" }} size="small">
        <Autocomplete
          key={"brand"}
          disabled={!selectedCity}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione Marca*"
              sx={{ bgcolor: "white" }}
            />
          )}
          value={selectedBrand}
          options={brands}
          getOptionLabel={(option) => option.brandId || ""}
          onChange={(_, value) => {
            setSelectedBrand(value ?? null);
          }}
        />
      </FormControl>
      <FormControl sx={{ m: 1, width: "304px" }} size="small">
        <Autocomplete
          key={"platform"}
          disabled={!selectedBrand}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione Plataforma*"
              sx={{ bgcolor: "white" }}
            />
          )}
          value={selectedPlatform}
          options={platforms}
          getOptionLabel={(option) => option.name || ""}
          onChange={(_, value) => {
            setSelectedPlatform(value ?? null);
          }}
        />
      </FormControl>
      <FormControl sx={{ m: 1, width: "304px" }} size="small">
        <Autocomplete
          key={"kitchen"}
          disabled={!selectedPlatform}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione Cocina*"
              sx={{ bgcolor: "white" }}
            />
          )}
          value={selectedKitchen}
          options={kitchens}
          getOptionLabel={(option) => option.kitchenId || ""}
          onChange={(_, value) => {
            setSelectedKitchen(value ?? null);
          }}
        />
      </FormControl>
      {coverages.length === 1 &&
        coverages[0].coordinates != null &&
        coverages[0].coordinates.list.length > 0 && (
          <>
            <Divider
              sx={{ width: "100%", borderColor: "#D7D3D3", mt: 1, mb: 2 }}
            />
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              gap={1}
            >
              <Box sx={{ display: "flex", alignContent: "center" }}>
                <Icon>info_black_outlined</Icon>
                <Typography
                  sx={{ ml: 1 }}
                  variant="h6"
                  fontWeight="600"
                  fontSize="16px"
                >
                  {selectedBrand?.brandId} - {selectedKitchen?.kitchenId}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ ml: 4 }}
                  variant="body1"
                  fontWeight="700"
                  fontSize="14px"
                  width={1 / 2}
                >
                  Área:
                </Typography>
                <Typography variant="body1" fontWeight="400" fontSize="16px">
                  {`${coverages[0].area.toFixed(2)} km2`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ ml: 4 }}
                  variant="body1"
                  fontWeight="700"
                  fontSize="14px"
                  width={1 / 2}
                >
                  Área teórica:
                </Typography>
                <Typography variant="body1" fontWeight="400" fontSize="16px">
                  {`${COVERAGE_THEORICAL_DEFAULT} km2`}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{ ml: 4 }}
                  variant="body1"
                  fontWeight="700"
                  fontSize="14px"
                  width={1 / 2}
                >
                  Coords. evaluadas:
                </Typography>
                <Typography variant="body1" fontWeight="400" fontSize="16px">
                  {coverages[0].coordinates.evaluated}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{ ml: 4 }}
                  variant="body1"
                  fontWeight="700"
                  fontSize="14px"
                  width={1 / 2}
                >
                  Última actualización:
                </Typography>
                <Typography variant="body1" fontWeight="400" fontSize="16px">
                  hace {timeHumanize(new Date(coverages[0].lastUpdated))}
                </Typography>
              </Box>
            </Box>
          </>
        )}
    </Box>
  );
};

export default FilterMapsBistro;
