export interface Platform {
  polygonCountries: string[];
  name: string;
  value: string;
}

export const Platforms: Platform[] = [
  {
    value: "Rappi",
    name: "RAPPI",
    polygonCountries: ["COL", "PER", "ECU", "BRA", "MEX"],
  },
  {
    value: "IFood",
    name: "IFOOD",
    polygonCountries: ["BRA"],
  },
  {
    value: "UberEats",
    name: "UBER",
    polygonCountries: ["MEX"],
  },
];
