import {
  GoogleMap,
  MarkerF,
  PolygonF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { FunctionComponent, memo, useRef } from "react";
import configProvider from "../../../config.provider";
import { KitchenPolygon } from "../../../core/store/Store";
import useAppSelector from "../../hooks/useAppSelector";

const containerStyle = {
  width: "100%",
  height: `calc(100vh - 56px)`,
  overflow: "hidden",
};

interface PolygonMapsProps {
  coverage: KitchenPolygon[];
}

const PolygonMap: FunctionComponent<PolygonMapsProps> = (props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: configProvider.maps.apiKey,
  });
  const mapRef = useRef<any>(null);
  const { version } = useAppSelector((state) => state.mapsState);

  const { coverage } = props;
  console.log(coverage);

  return isLoaded ? (
    <GoogleMap
      ref={mapRef}
      key={`version-${version}`}
      mapContainerStyle={containerStyle}
      center={coverage.length > 0 ? coverage[0].location : undefined}
      zoom={coverage.length === 1 ? 14 : 12}
    >
      <>
        {coverage.map((cov) => {
          return (
            <>
              <MarkerF position={cov.location} title={cov.kitchenId}></MarkerF>
              {cov.area > 0 && (
                <PolygonF
                  paths={cov.coordinates.list}
                  options={{
                    fillColor: "#2ecc71",
                    fillOpacity: 0.2,
                    strokeColor: "#27ae60",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    geodesic: false,
                    zIndex: 100,
                  }}
                ></PolygonF>
              )}
            </>
          );
        })}
      </>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default memo(PolygonMap);
