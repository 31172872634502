import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { KitchenPolygon } from "../../../core/store/Store";
import { setTitle } from "../../../store/slices/screen.slice";
import { PolygonMap } from "../../components/PolygonMap";
import useAppDispatch from "../../hooks/useAppDispatch";
import FilterMapsBistro from "./filterMap";

const MapsPage = () => {
  const dispatch = useAppDispatch();

  const [coverages, setCoverages] = useState<KitchenPolygon[]>([]);

  useEffect(() => {
    dispatch(setTitle("Mapas"));
  }, []);

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          position: "absolute",
          top: "60px",
          left: "90px",
          background: "white",
          width: "352px",
          zIndex: 3,
        }}
      >
        <Box>
          <FilterMapsBistro setCoverage={setCoverages} />
        </Box>
      </Box>
      {coverages.length > 0 && <PolygonMap coverage={coverages} />}
    </Box>
  );
};

export default MapsPage;
